import React from 'react'
import { Col, Image, Row } from 'antd'
import sender from '../../../asset/image/42w.png'

const ListComponent = ({ obj }) => {
    return (
        <>


            {(obj?.title === 'Likes' || obj?.title === 'Comments') && <Row className=''>
                <Col span={5}>
                    <Image src={obj?.clickAction?.CommenterImage || sender} alt='image' width={40} height={40} className='rounded-full object-fill' />
                </Col>
                <Col span={16}>
                    <p className='mr-1 text-xs'>
                        {obj?.body}
                    </p>
                </Col>
                <Col span={3} className='-mt-1'>
                    <Image src={obj?.clickAction?.postImage || sender} alt='image' width={40} height={40} className='rounded-full object-fill' />
                </Col>
            </Row>}
            {(obj?.title === 'Followers') && <Row className=''>
                <Col span={5}>
                    <Image src={obj?.clickAction?.userImage || sender} alt='image' width={40} height={40} className='rounded-full object-fill' />
                </Col>
                <Col span={16}>
                    <p className='mr-1 text-xs'>
                        {obj?.body}
                    </p>
                </Col>
            </Row>}
            {(obj?.title === 'New Event') && <Row className=''>
                <Col span={5}>
                    <Image src={obj?.clickAction?.ClubImage || sender} alt='image' width={40} height={40} className='rounded-full object-fill' />
                </Col>
                <Col span={16}>
                    <p className='mr-1 text-xs'>
                        {obj?.body}
                    </p>
                </Col>
                <Col span={3} className='-mt-1'>
                    <Image src={obj?.clickAction?.eventImage || sender} alt='image' width={40} height={40} className='rounded-full object-fill' />
                </Col>
            </Row>}

        </>
    )
}

export default ListComponent