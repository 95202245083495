import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputNumber,
  Modal,
  Pagination,
  Row,
  Tabs,
  notification,
} from "antd";
import { DownOutlined, UpOutlined, SettingOutlined, PlusOutlined } from "@ant-design/icons";

// import HeadDashboard from "../../../component/card/Head-Dashboard";
// import PlantOverviewCard from "../../../component/card/Plant-Overview-Card";
// import ClientOverviewCard from "../../../component/card/Client-Overview-Card";
// import TaskCard from "../../../component/card/Task-Card";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
// import { DeleteOutlined, LogoutOutlined } from "@ant-design/icons";
import { SlLogout } from "react-icons/sl";
import imageSrc from "../../../asset/image/clubs.png";
import Heading from "../../../component/common/Heading";
import { deleteAuthDetails, setAuthDetails } from "../../../util/API/authStorage";
import PostTab from "./tabs/Post-tab";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import axios from "axios";
import ListComponent from "./list-component";


const ClubProfile = () => {
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationOpen, setisNotificationOpen] = useState(false)
  const [isPostUploaded, setIsPostUploaded] = useState(false)
  const [refresh, setRefresh] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isListOpen, setIsListOpen] = useState(true);
  const [clubDetails, setClubDetails] = useState({});
  const [followerList, setFollowerList] = useState([]);
  const [editBankDetail, setEditBankDetail] = useState(null);
  const [MOBILE_TOKEN, setMOBILE_TOKEN] = useState("");
  const [postRefresh, setPostRefresh] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(3);
  const [totalRecords, setTotalRecords] = useState(null)
  const [notificationSetting, setNotificationSetting] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const api = useHttp();
  const subMenus = [
    { post: <PostTab refresh={postRefresh} /> },
    // { events: <EventTab /> }
  ];




  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <p className="px-3 hover:text-blue-500">Previous</p>;
    }
    if (type === 'next') {
      return <p className="px-3 hover:text-blue-500">Next</p>;
    }
    return originalElement;
  };

  const handleChangePage = (page, pageSize) => {

    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  // Logout Handler
  const logOutHandler = () => {
    deleteAuthDetails();
    localStorage.clear();
    // navigate("/");
    window.location.assign("/");
  };

  // Edit Mobile Number
  const submitMobileData = (value) => {
    const payload = {
      mobile: +value.mobile,
    };
    // console.log(payload);
    const UPDAET_MOBILE_API = { ...CONSTANTS.API.sendUpdateOTP }
    api.sendRequest(UPDAET_MOBILE_API
      ,
      (res) => {
        setMOBILE_TOKEN(res?.token);
        // MOBILE_TOKEN.token = res?.token
        setIsValid((prev) => !prev);
      },
      payload,
      "Send OTP"
    );
  };
  const verifyOTP = (e) => {
    setIsLoading((prev) => !prev);
    // console.log("clicked");
    // console.log(e);
    const payload = {
      OTP: e.OTP,
    };

    // console.log(payload);
    (async () => {
      const Services = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // timeout: 10000,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${MOBILE_TOKEN}`,
        },
      });
      try {
        const response = await Services.patch("/clubs/verify/OTP", payload);
        setAuthDetails(response?.data?.token);
        setIsLoading((prev) => !prev);
        notification.success({
          message: "Update Mobile Number Successfully!!!",
          duration: "2",
        });
        setIsModalOpen((prev) => !prev);
      } catch (err) {
        setIsLoading((prev) => !prev);
        if (err?.response?.data?.message) {
          notification.error({
            message: err?.response?.data?.message,
            duration: "3",
          });
        } else {
          notification.error({
            message: "Something Wrong Please Try again",
          });
        }
      }
    })();
  };

  // Edit Bank Detials
  const editBankDetailData = (value) => {
    const payload = value;
    // console.log(payload);
    const UPDATE_BANK_DETAILS_API = { ...CONSTANTS.API.editMyProfile };
    api.sendRequest(
      UPDATE_BANK_DETAILS_API,
      () => {
        setRefresh((prev) => !prev);
        setEditBankDetail(null);
      },
      payload,
      "Bank Details Update Successfully"
    );
  };

  // Update Notifications 
  const upadteNotifications = (value) => {
    const payload = { ...value }
    // console.log(value);
    const UPDATE_NOTIFICATION_API = { ...CONSTANTS.API.setNotification }
    api.sendRequest(UPDATE_NOTIFICATION_API, () => {
      setRefresh(prev => !prev)
      setNotificationSetting(null)
    }, payload, 'Update Notification Successfully !!!')
  }

  // Upload Post
  const uploadPost = (value) => {
    const mediaType = ((value?.fileImage[0]?.type)?.split('/'))[0] === 'video' ? 'Video' : 'Image';
    // console.log(mediaType);
    const payload = new FormData();
    payload.append('file', value.fileImage[0].originFileObj)
    value.description && payload.append('description', value.description)
    payload.append('mediaType', mediaType)
    value.thumbnailImage && payload.append('thumbnail', value.thumbnailImage[0].originFileObj)



    const UPLOAD_POST_API = { ...CONSTANTS.API.addClubPost }

    api.sendRequest(UPLOAD_POST_API, () => {
      setIsPostUploaded(prev => !prev)
      setPostRefresh(prev => !prev)
    }, payload, 'Post Uploaded Successfully!!!')

  }

  // Get Club profile Data
  useEffect(() => {
    const CLUB_PROFILE_API = { ...CONSTANTS.API.getMyProfile };
    api.sendRequest(CLUB_PROFILE_API, (res) => {
      // console.log(res);
      setClubDetails(res?.data?.club);
    });



  }, [refresh]);
  // Pagination
  useEffect(() => {
    const CLUB_FOLLOWERS_API = { ...CONSTANTS.API.getClubFollowers }
    CLUB_FOLLOWERS_API.endpoint = `${CLUB_FOLLOWERS_API.endpoint}&page=${currentPage}&limit=${currentPageSize}`
    api.sendRequest(CLUB_FOLLOWERS_API, (res) => {
      setFollowerList(res?.data?.followers);
      setTotalRecords(res?.totalRecords)

    })
  }, [currentPage, currentPageSize])
  // Get Notification

  useEffect(() => {
    const NOTIFICATION_LIST = { ...CONSTANTS.API.getNotificationList }
    api.sendRequest(NOTIFICATION_LIST, (res) => {
      setNotifications(res?.data?.clubNotifications?.map(data => {
        return {
          ...data,
          clickAction: JSON.parse(data?.clickAction)
        }
      }))
    })
  }, [])
  return (
    <div className="px50 py30">
      <Row className="mt-5 ">
        <p className="text-2xl font-medium">Club Profile</p>
      </Row>

      <div className="">
        <Row gutter={[16, 16]}>
          <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8}>
            <Card className=" ">
              <div>
                <div className="mt-3">
                  <Row>
                    <Col span={7} lg={7} xl={7} xxl={6}>
                      <Image
                        width={80}
                        height={80}
                        className="rounded-full"
                        preview
                        src={clubDetails?.image || imageSrc}
                        alt="Images is Not Availabel"
                      />
                    </Col>
                    <Col span={16} lg={16} xl={16} xxl={16}>
                      <p className="font-medium mt-3 px-3 text-2xl">
                        {clubDetails?.name || "No data Found"}
                      </p>
                    </Col>
                  </Row>
                </div>
                <p className="text-justify mt-2">
                  {clubDetails?.bio || "No data Found"}
                </p>

                <div className="flex mt-3 text-xl">
                  <SettingOutlined className="pr-3" />
                  <p
                    onClick={(e) => e.preventDefault()}
                    className="pt-4 font-medium"
                  >
                    Setting
                  </p>
                  {!isSettingOpen ? (
                    <DownOutlined
                      className="pl-10"
                      onClick={() => {
                        setIsSettingOpen((prev) => !prev);
                      }}
                    />
                  ) : (
                    <UpOutlined
                      className="pl-10"
                      onClick={() => {
                        setIsSettingOpen((prev) => !prev);
                      }}
                    />
                  )}
                </div>
                {isSettingOpen && (
                  <div>


                    <Row>
                      <Col>
                        <div>
                          <Row className="">
                            <div>
                              <p
                                className=" pl-5 hover:text-slate-400  mt-5 cursor-pointer "
                                onClick={() => {
                                  setIsModalOpen((prev) => !prev);
                                }}
                              >
                                Update Mobile Number
                              </p>
                            </div>
                          </Row>
                          <Row className="">
                            <div className="">
                              <p
                                className=" pl-5 hover:text-slate-400  cursor-pointer  "
                                onClick={() => {
                                  setNotificationSetting(clubDetails?.ClubNotificationConfig)
                                }}
                              >
                                Notification Setting
                              </p>
                            </div>
                          </Row>
                          <Row className="">
                            <div className="">
                              <p
                                className=" pl-5 hover:text-slate-400  cursor-pointer "
                                onClick={() => {
                                  setEditBankDetail(clubDetails);
                                }}
                              >
                                Update Bank Details
                              </p>
                            </div>
                          </Row>
                          <Row className="">
                            <div className="ml-5">
                              <Button
                                className="block "
                                type="primary"
                                onClick={logOutHandler}
                                danger
                              >
                                <SlLogout />
                                <p className="inline-block pl-2">Log Out</p>
                              </Button>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Card>
            <Card className={`mt-5 ${isNotificationOpen ? 'overflow-y-scroll' : 'overflow-hidden'}`} style={{ height: `${isNotificationOpen ? '80vh' : '15vh'}` }}>
              <div>
                <Row>
                  <Col span={18}>
                    <Heading>
                      Notification
                    </Heading>
                  </Col>
                  <Col span={4} className="mt-2">
                    {!isNotificationOpen ? (
                      <DownOutlined
                        className="pl-10 text-xl"
                        onClick={() => {
                          setisNotificationOpen((prev) => !prev);
                        }}
                      />
                    ) : (
                      <UpOutlined
                        className="pl-10 text-xl"
                        onClick={() => {
                          setisNotificationOpen((prev) => !prev);
                        }}
                      />
                    )}
                  </Col>
                </Row>

                {isNotificationOpen && <div>
                  {notifications?.map(data =>
                    <div className='my-2' key={data?.id}>
                      <ListComponent obj={data} />
                    </div>
                  )}
                </div>}
              </div>
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
            <Card className="mb-3 cursor-pointer" onClick={() => {
              setIsPostUploaded(prev => !prev);

            }}>
              <p className="text-center p-0 m-0 font-semibold text-xl">
                <PlusOutlined /> Upload Post
              </p>
            </Card>
            <Card className=" overflow-y-scroll">
              <Tabs
                className="p-0 m-0"
                defaultActiveKey="1"
                size="middle"
                centered
                items={CONSTANTS.TAB_MENU.DASHBOARD.map((ele) => {
                  return {
                    label: `${ele.Label}`,
                    key: `${ele.id}`,
                    children: subMenus.map((data) => {
                      if (Object.keys(data)[0] === ele.id) {
                        return data[ele.id];
                      }
                    }),
                  };
                })}
              />
            </Card>
          </Col>
          <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
            <Card>
              <Row className="mb-5">
                <Col span={12}>
                  <h2 className="font-bold">Following</h2>
                </Col>
                <Col span={12}>
                  <div className="float-right mr-4 mt-1 text-xl">
                    {isListOpen ? (
                      <UpOutlined
                        className=""
                        onClick={() => {
                          setIsListOpen((prev) => !prev);
                        }}
                      />
                    ) : (
                      <DownOutlined
                        className=""
                        onClick={() => {
                          setIsListOpen((prev) => !prev);
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              {isListOpen &&
                followerList?.map((ele) => {
                  // console.log(ele);
                  return (
                    <div key={ele?.id}>
                      <Row className="py-1">
                        <Col span={4}>
                          <Image
                            className="rounded-full"
                            src={ele?.User?.profilePic}
                            preview
                            width={40}
                            height={40}
                          />
                        </Col>
                        <Col span={12}>
                          <p className="text-xl">
                            {ele?.User?.userName || "No data Found"}
                          </p>
                        </Col>
                        <Col span={8}>
                          <p className="float-right text-xl mt-2">
                            {ele?.place}
                          </p>
                        </Col>
                      </Row>
                      <hr className="border-solid border opacity-10 border-slate-400 -mt-1" />
                    </div>
                  );
                })}
              {isListOpen && <Pagination
                current={currentPage}
                pageSize={currentPageSize}
                total={totalRecords}
                onChange={handleChangePage}
                className="mt-10"
                size="small"
                showLessItems
                itemRender={itemRender}
              />}
            </Card>
          </Col>
        </Row>
        {/* Update Mobile Number Modal */}
        <Modal
          title="Update Mobile Number"
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen((prev) => !prev);
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <Form className="text-white mt-5" onFinish={submitMobileData}>
            <Form.Item
              name="mobile"
              rules={[

                {
                  required: true,
                  message: "Please Enter Mobile Number!",
                },
                () => ({
                  validator(_, value) {
                    if (value && /^\d{10}$/.exec(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please Enter valid mobile number")
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                name="mobile"
                id="mobile"
                placeholder="Enter Mobile Number"
                className={`${api.isLoading ? "w-full bg-white text-slate-500" : "w-full"
                  }`}
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={10}
                disabled={api.isLoading}
              />
            </Form.Item>
            {!isValid && (
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="block m-auto"
                  type="primary"
                  loading={api.isLoading}
                >
                  Verify Mobile Number
                </Button>
              </Form.Item>
            )}
          </Form>
          {isValid && (
            <Form onFinish={verifyOTP}>
              <Form.Item
                name="OTP"
                rules={[
                  {
                    required: true,
                    message: "Invalid OTP!",
                  },
                ]}
              >
                <InputNumber
                  name="OTP"
                  id="OTP"
                  placeholder="Enter OTP"
                  className={`${isLoading ? "w-full bg-white  text-slate-500" : "w-full"
                    }`}
                  disabled={api.isLoading}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="block m-auto"
                  type="primary"
                  loading={isLoading}
                >
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
        {/* Update Bank Details */}
        <ModalFormCreator
          open={editBankDetail !== null}
          onCreate={editBankDetailData}
          onCancel={() => {
            setEditBankDetail(null);
          }}
          name="Update Bank Details"
          menu="UPDATE_BANK_DETAILS_MODAL"
          formData={{ ...editBankDetail }}
        />
        {/* Upload Post Modal */}
        <ModalFormCreator
          open={isPostUploaded}
          onCreate={uploadPost}
          onCancel={() => {
            setIsPostUploaded(prev => !prev);
          }}
          name="Add Post Details"
          menu="POST_UPLOAD_MODAL"

        />
        {/* Notification Setting */}
        <ModalFormCreator
          open={notificationSetting !== null}
          onCreate={upadteNotifications}
          onCancel={() => {
            setNotificationSetting(null);
          }}
          name="Notification Setting"
          menu="NOTIFICATION_MODAL"
          formData={{ ...notificationSetting }}
        />


      </div>
    </div>
  );
};

export default ClubProfile;
